import {Container,Row,Col,Button} from 'react-bootstrap'
import React from "react"
import Layout from "../components/layouts/layout"
import SEO from "../components/seo"
import Pageart from "../components/pageart/pageart"
import {Link} from "gatsby"

function SignUpForm() {

  return (

    <Layout>
      <div className="signup signup-success">

        <SEO title="Sign Up" />

        <Pageart />

          <Container className="lift">
            <Row>
              <Col className="content-wrapper">

                <div className="submit-success">
                  <h2 className="fs-24 pb-2"><strong>Thank you</strong> <br className="d-lg-none" />for signing up</h2>
                  <p className="fs-24 pb-4">Stay tuned to learn more about how to protect your heart.</p>
                  <p>
                    <Link className="btn btn-primary" to="/">HOME</Link>
                  </p>
                </div>

              </Col>
            </Row>
          </Container>

      </div>
    </Layout>

  );
}

export default SignUpForm
